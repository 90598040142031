export default (pageContext) => {
  const { urlPathname } = pageContext;
  const match = urlPathname.match(/\/pulse\/preview\/([^-]+)/)

  if (match) {
    const [, id] = match;
    return {
      routeParams: { id: String(id) }
    };
  }

  return false;
};
