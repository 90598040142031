export default (pageContext) => {
  const { urlPathname } = pageContext;
  const match = urlPathname.match(/\/pulse\/articles\/([^-]+)-(.+)/)
  
  if (match) {
    const [, id, slug] = match;
    return {
      routeParams: { id: String(id), slug: String(slug) }
    };
  }
  
  return false;
};